import Footer from "../Components/Footer";

function Experience() {
  const workHistory = [
    { jobTitle: "Application Architect", company: "Eli Lilly", duration: "Oct'24-Present", description: "I develop and maintain efficient automation solutions using Nexthink Flow, driving the design, implementation, and integration of Nexthink with other platforms. Strong expertise in API design, development, and integration, collaborating closely with cross-functional teams to gather requirements and deliver solutions that align with business objectives." },
    { jobTitle: "Automation Engineer", company: "Best Buy", duration: "May'24-Oct'24", description: "Azure and other server automation, mixed with AI and Chatbots. Constantly finding pain points for other teams, and making their lives simple through automating their processes. Creating web portals/pages/apps via React is a large portion of my daily work. I also have a hand in just about anything related to End User Experience that invovles powershell, javascript, or C#. I have been the goto for a lot of new projects, due to my attention to small details and UI/UX skills." },
    { jobTitle: "END USER COMPUTING – SENIOR SPECIALIST", company: "Best Buy", duration: "Aug '23-May'24", description: "UX design for Best Buy's internal software requests process, as well as the self-help app on all end user devices. Leveraging reporting systems we have put in place via Nexthink, keeping end user machines running smoothly. I spend a good chunk of time breaking down large scale issues into manageable workloads, as well as coaching other folks to have the same mindset and approach." },
    { jobTitle: "DIGITAL ACCELERATOR ENGINEER", company: "Best Buy", duration: "Jan'24-Mar'24", description: "[Stretch Assignment] I expanded on the backend of the ChromaDB, Vertex LLM of this AI project. I then build the entire front end using React to createa seamless chatbot experience to showcase this AI. We created several ‘Templates’ for this AI, which can vary per use case, and designed the chatbot to select the best fit one for the user’s questions." },
    { jobTitle: "SERVICE-NOW ENGINEER", company: "Best Buy", duration: "Aug'23-Dec'23", description: "[Stretch Assignment] Created forms that had been requested by other teams, these pages intergrade with many other backends that Best Buy uses (Active Directory for example). I also configured new access and roles within SNOW for these pages." },
    { jobTitle: "END USER COMPUTING – ASSOCIATE ENGINEER", company: "Best Buy", duration: "Jan'23-Aug'23", description: "[Stretch Assignment] I spent a good portion of this time working on the Service Now Virtual Agent chatbot. This was a large-scale project that reallyhelped me break up large tasks into smaller chunks. Once that was done, I moved on to migrating and re-writing old automation from our orchestrator server into Azure Cloud." },
    { jobTitle: "END USER COMPUTING – SPECIALIST II & END USER SUPPORT", company: "Geek Squad", duration: "Oct’19-Aug’23", description: "Monitoring ticket queues, working with end users on fixing their problems. All my downtime went into developing apps to help be more efficient, or creating new process that would eliminate the need for/the cause of the tickets in the first place. Moving into this role, it took less than 3 months before I had completely stopped all tickets coming to my team, and was able to fully focus on proactive work, and not reactive." },
    { jobTitle: "CYBER SEC: OFFENSIVE & PEN TESTING", company: "National Guard", duration: "June’18-Oct’19", description: "Finding data breaches and patching them. If no active breach was reported and being worked on, time was then spent trying to create a breach to test the efficiency of the system at detecting and alerting the breach. I loved the work, but the paperwork was less than desirable. However, writing these reports have made my writing and written presentations look and read a lot better. A lot of this work is still under NDA." },
    { jobTitle: "DOUBLE AGENT – PC, HT, & APPLIANCE REPAIR", company: "Geek Squad", duration: "Jan’17-Oct’19", description: "What started as just the guy in the Geek Squad bug going to homes to fix PC issues, turned into taking on every field role Geek Squad offers. Mounting TVs, repairing dish washers, and setting up mesh wi-fi networks for business. I was the only field agent in WI, MN, ND, and IL that was certified to do Best Buy business networking solutions and was often put in hotels to go to those states." },
    { jobTitle: "ADVANCED REPAIR AGENT", company: "Geek Squad", duration: "May’16-Jan’17", description: "Repairing and setting up computers brought into my local Best Buy store. Installing new components or replacing phone screens were also on the to do list often." },
    { jobTitle: "AUTO TECH", company: "Geek Squad", duration: "Aug’15-May’16", description: "Installing remote starts, everything related to car audio, and automotive security systems. I was going to school at this time, and used my newfound love for car electronics, mixed with the robotics and computing classes to create my own remote start and security system with a raspberry pi, and hours of headaches. (This was in my car for 6 years before I sold it)" },
    { jobTitle: "SALES LEAD – DIGITAL IMAGERY", company: "Best Buy", duration: "May’15-Aug’15", description: "Quickly being identified an expert on all thing’s cameras, I was appointed the lead of the department to aid in training." },
    { jobTitle: "SALES – COMPUTERS AND DIGITAL IMAGERY", company: "Best Buy", duration: "Feb’15-May’15", description: "Selling computers to folks that were in need, while listening to their needs to make sure they were getting the best fit. This was my first position with Best Buy, February 4th, 2015." }
  ];

  const education = [
    { degree: "Master's in Cybersecurity", school: "Western Governors University", year: "2016-2019" },
    { degree: "Master's in Networking", school: "Western Governors University", year: "2016-2019" },
    { degree: "General Studies", school: "Chippewa Valley Technical College", year: "2014-2016" }
  ];

  const certificates = [
    { certificateName: "Complete React Developer (/w Redux, Hooks, GraphQL)", issuer: "Udemy.com", year: "2024" },
    { certificateName: "Certified Nexthink Associate", issuer: "Nexthink.com", year: "2023" },
    { certificateName: "Certified System Administrator", issuer: "ServiceNow.com", year: "2022" },
    { certificateName: "IT Supporter", issuer: "Nexthink.com", year: "2022" },
    { certificateName: "Offensive Security Exploitation Expert", issuer: "OffSec.com", year: "2018" },
    { certificateName: "Offensive Security Wireless Professional", issuer: "OffSec.com", year: "2018" },
    { certificateName: "Offensive Security Certified Expert", issuer: "OffSec.com", year: "2018" },
    { certificateName: "Offensive Security Web Expert", issuer: "OffSec.com", year: "2018" },
    { certificateName: "Offensive Security Certified Professional", issuer: "OffSec.com", year: "2018" },
    { certificateName: "Mobile Electronic Certified Professional", issuer: "MECP.com", year: "2016" },
    { certificateName: "PenTest+", issuer: "Comptia.org", year: "2016" },
    { certificateName: "CySA+", issuer: "Comptia.org", year: "2016" },
    { certificateName: "Server+", issuer: "Comptia.org", year: "2016" },
    { certificateName: "Network+", issuer: "Comptia.org", year: "2016" },
    { certificateName: "Security+", issuer: "Comptia.org", year: "2016" },
    { certificateName: "A+", issuer: "Comptia.org", year: "2016" }
  ];

  return (
    <>
      <div className="main-content">
        <h1 class="hero glitch layers" data-text="近設計"><span>Education</span></h1>
        {education.map((edu, index) => (
          <div key={index}>
            <hr/>
            <h4>{edu.degree}</h4>
            <p><b>{edu.school}</b> • <i>{edu.year}</i></p>
          </div>
        ))}
        <h1 class="hero glitch layers" data-text="近設計"><span>Certificates</span></h1>
        {certificates.map((cert, index) => (
          <div key={index}>
            <hr/>
            <h4>{cert.certificateName}</h4>
            <p><b>{cert.issuer}</b> • <i>{cert.year}</i></p>
          </div>
        ))}
        <h1 class="hero glitch layers" data-text="近設計"><span>Work History</span></h1>
        {workHistory.map((job, index) => (
          <div key={index}>
            <hr/>
            <h4>{job.jobTitle}</h4>
            <p><b>{job.company}</b> • <i>{job.duration}</i></p>
            <p>{job.description}</p>
            <p></p>
          </div>
        ))}
      </div>

    <center><p>If you scrolled this far, you must think I know what I am doing.</p></center>
      <Footer/>
    </>
  );
}

export default Experience