import React from "react";
import "../App.css";
import Landing from "../Components/Landing";

function Home({ setPage }) {
  return (
    <>
      <Landing />
      <section id="home">
        <div className="wrapper">
          <div className="content">
            <h1>Howdy, I'm Alx.</h1>
            <p className="quote">
              "If you could wave a wand and fix user issues, what would you fix?"<br />
              <span><p className="magic-font">Show me your problems, and I will take care of the rest.</p></span>
            </p>
            <p>
              I have a strong background in automation, API development, and integration, with a focus on improving workflows and enhancing user experiences.
              My experience includes leading projects with Nexthink Flow, ServiceNow, and AI chatbot solutions, as well as developing intuitive front-end applications using React and implementing automation with PowerShell and Azure.
            </p>
            <p>
              I excel at breaking down complex problems into actionable solutions and working closely with teams to deliver results.
              My attention to detail and focus on UI/UX design ensure the solutions I create are both efficient and user-friendly.
            </p>
            <div className="button-container"> {/* Add button-container here */}
              <button onClick={() => setPage('experience')} className="nav-button">
                My Experience
              </button>
              <button onClick={() => setPage('projects')} className="nav-button">
                My Projects
              </button>
              <a 
                href="https://github.com/Alx-Benjamin" 
                target="_blank" 
                rel="noopener noreferrer"
                className="nav-button github-button"
              >
                My GitHub
              </a>
            </div>
          </div>
          <br />
          <br />
        </div>
      </section>
    </>
  );
}

export default Home;
