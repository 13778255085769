import { useEffect } from "react";

function ScrollToTop({ page }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]); 

  return null;
}

export default ScrollToTop;
