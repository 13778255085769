import React, { useState } from 'react';
import "../src/App.css";
import "./Components/Glitch.css";
import NavigationMenu from './Components/NavigationMenu';
import Home from './Pages/Home';
import Bio from './Pages/Bio';
import Experience from './Pages/Experience';
import Projects from './Pages/Projects';
import 'bootstrap/dist/css/bootstrap.min.css';
import ScrollToTop from './Components/ScrollToTop';

function App() {
  const [page, setPage] = useState('home');

  let content;
  switch (page) {
    case 'home':
      content = <Home setPage={setPage} />;
      break;
    case 'bio':
      content = <Bio />;
      break;
    case 'experience':
      content = <Experience />;
      break;
    case 'projects':
      content = <Projects />;
      break;
    default:
      content = <Home setPage={setPage} />;
  }

  return (
    <div className="App">
      <ScrollToTop page={page} />
      <NavigationMenu setPage={setPage} />
      {content}
    </div>
  );
}

export default App;
